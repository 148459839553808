.Login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 93px 24px 0;
}

.Login>img {
    height: 52%;
    width: auto;
    margin-bottom: 48px;
}

.Login .inp {
    position: relative;
    width: 100%;
    height: 54px;
    background: #192430;
    border: 1px solid #334663;
    border-radius: 12px;
    transition: all .3s;
    margin-bottom: 23px;
}

.Login .inp:focus-within {
    border-color: #9C94FF;
}

.Login .inp:focus-within>svg path {
    stroke: #9C94FF !important;
}

.Login .inp>input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 0;
    padding: 0 46px 0 20px ;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0;
    color: #FFFFFF;
}

.Login .inp>svg {
    position: absolute;
    top: calc(50% - 12px);
    right: 12px;
    transition: all .3s;
}

.Login .sub {
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #9C94FF;
    border-radius: 12px;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}