.footerNav {
    background: #000000;
    /* position: absolute; */
    left: 0;
    bottom: 0;
    right: 0;
    height: 83px;
    width: 100%;
    /* -webkit-app-region: drag; */
    /* z-index: 20000; */
    z-index: 21055;
}

.footerNav ul {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0;
    height: 70%;
    padding: 0 8.33%;

}

.footerNav ul li {
    height: 100%;
    min-width: 32px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.footerNav ul li a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul li .isNow {
    display: none;
    position: absolute;
    top: 0;
    transition: all .3s;
}

ul li .routerTarget path {
    fill: white;
}

ul li .a path {
    fill: rgb(143 143 143);
}

ul .nowTarget .routerTarget path {
    fill: #9C94FF;
}

ul .nowTarget .a path {
    fill: #5c5791;
}

.nowTarget .isNow {
    display: block;
}

.footerNav ul .crown {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    flex-shrink: 0;
    align-self: flex-end
}

.footerNav ul .crown div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.footerNav ul .crownACtive {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    flex-shrink: 0;
    align-self: flex-end
}

.footerNav ul .crownACtive div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    background: radial-gradient(93.33% 93.33% at 65.83% 25.83%, #FFC700 0%, #A7896E 100%);
}

.footerNav ul li img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.6);
}