.appFriends {
    color: #FFFFFF;
    flex: 1;
    padding-bottom: 82px;
    font-family: Open !important;
}

p {
    margin: 0
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}


.change {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 12px 0;
    padding: 0 16px
}

.change svg {
    cursor: pointer;
}

.line {
    width: calc(100% - 16px);
    height: 1px;
    background-color: #334663;
    margin-bottom: 12px;
    margin-left: auto;
}

.chats .listss {
    width: 100%;
    margin: 32px 0;
    padding: 0 16px;
}

.chats .listss li {
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
}

.chats .listss li>span {
    flex-shrink: 0;
    display: flex;
    padding: 2px 11px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #9C94FF;
    color: #2D323E;
    font-size: 12px;
    font-family: Open Sans;
}

.chats .listss li>div {
    display: flex;
    align-items: center;
}

.chats .lists li:last-child {
    margin-bottom: 0;
}

.chats .listss li svg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
}

.chats .friendlistname {
    padding-left: 16px;
    color: #FFF;
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 22px;
}

/* getstream */
.channelPreviewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 37px;
    padding: 0;
}

.channel_preview__item {
    display: flex;
    align-items: center;
    max-width: 220px;
    height: 100%;
    width: 100%;
    padding-left: 26px;
}

.single {
    margin-right: 12px;
}

.multi {
    margin-right: 0;
}

.multi span:first-child {
    position: relative;
    z-index: 2;
    bottom: 6px;
}

.multi div:nth-child(2) {
    position: relative;
    right: 10px;
    z-index: 1;
    margin-bottom: 0;
}

/* getstream */

.serarch {
    width: 100%;
    height: 40px;
    padding: 0 16px;
    margin-bottom: 16px;
}

.serarch .searchInputBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000;
    padding-left: 16px;
    border-radius: 8px;
}

.serarch input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: #000000;
    border-radius: 8px;
}

.serarch .iconClick {
    flex-shrink: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.lists {
    width: 100%;
    padding: 4px 0 0 16px;
    overflow: hidden;
}


.lists li {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 12px;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
}


.lists li::-webkit-scrollbar {
    background: none;
    display: none;
}

.lists li::-webkit-scrollbar-thumb {
    display: none;
}

.lists li::-webkit-scrollbar-track {
    display: none;
}

.lists li::-webkit-scrollbar-button {
    display: none;
}

.lists li::-webkit-scrollbar-track-piece {
    display: none;
}


.lists li>img {
    width: 60px;
    height: 60px;
    margin-right: 16px;
    flex: 1;
}

.lists li .right {
    width: calc(100% - 92px);
    flex-shrink: 0;
    display: flex;
    padding-bottom: 6px;
    justify-content: space-between;
    border-bottom: 1px solid #334663;
    height: 70px;
    box-sizing: border-box;
}

.lists li .right .des span {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.lists li .right .des p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
    white-space: break-spaces;
    padding-top: 4px;
}

.lists li .right .time {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.lists li .right .time span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9C94FF;
    white-space: nowrap;
    margin-bottom: 16px;
}

.lists li .right .time div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9C94FF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #171821;
}

.lists li .block {
    width: 64px;
    height: 70px;
    flex-shrink: 0;
    background: #FF6881;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #000000;
}

/* modal  */

.appFriends ion-content::part(scroll) {
    --padding-start: 0;
    --padding-top: 0;
    --padding-end: 0;
    padding: 0;
}

/* Activemodal */
.activemodal {
    z-index: 25555 !important;
}

.activemodal ion-content {
    --background: #2D323E;
    --color: #FFFFFF;
    --border-radius: 8px;
}

.activemodal {
    --min-height: 100%;
    --min-width: 100%;
}

.commodal {
    padding-bottom: 0 !important;
    --min-height: 100%;
    --min-width: 100%;
    z-index: 25555 !important;
}

.activemodal ion-content::part(scroll)::before {
    display: none;
}

.activemodal ul {
    padding: 10% 10% 0;
    font-size: 16px;
    font-weight: 600;
}

.activemodal ul li {
    margin-bottom: 20px;
}

/* comment modal  */
.commentModal {
    position: relative;
    width: 100%;
    /* padding: 32px 16px 104px; */
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.commentModal .tit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    background: #000000;
    display: flex;
    align-items: center;
    padding-left: 24px;
    color: #FFFFFF;
    z-index: 9999;
    flex-shrink: 0;
}

.commentModal .tit .close svg {
    margin-right: 16px;
}

.commentModal .tit div {
    display: flex;
    align-items: center;
}

.commentModal .tit div img {
    margin-right: 10px;
    width: 32px;
    height: 32px;
}

.members {
    width: 100%;
    height: 32px;
    position: absolute;
    bottom: -36px;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    /* background: rgba(0, 0, 0, 0.6); */
}

.members li {
    margin-left: -6px;
    overflow: hidden;
    position: relative;
}

.members li:nth-child(1) {
    margin-left: 0;
}

.members li img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

/* group chat start  */
.commentModal .tit div .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.commentModal .tit div .group span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.commentModal .tit div .group p {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #CCCCCC;
}

.commentModal .groupPlay {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    background: #000000;
}

.commentModal .groupPlay .playLeft {
    display: flex;
    align-items: center;
}

.commentModal .groupPlay .playLeft>svg {
    margin-right: 8px;
}

.commentModal .groupPlay .playLeft>img {
    width: 48px;
    height: 36px;
    border-radius: 3.84px;
    margin-right: 8px;
}

.commentModal .groupPlay .playLeft .gameDes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.commentModal .groupPlay .playLeft .gameDes .top {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #CCCCCC;
    margin-bottom: 4px;
}

.commentModal .groupPlay .playLeft .gameDes .top span {
    color: #FFFFFF;
}

.commentModal .groupPlay .playLeft .gameDes>svg {
    margin-bottom: 4px;
}

.commentModal .groupPlay .playLeft .gameDes .bot {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: #CCCCCC;
}

.commentModal .groupPlay .playLeft .gameDes .bot>svg {
    margin-right: 8px;
}

.commentModal .groupPlay .playRight {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 15px;
    background: #9C94FF;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #000000;
}

.commentModal .groupPlay .playRight>svg {
    margin-right: 8px;
}

/* group chat end   */
.commentModal .main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.commentModal .main li {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.commentModal .main li:last-child {
    margin-bottom: 0;
}

.commentModal .main li .dateDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    /* background: #2D323E; */
    border-radius: 16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #A6A9B8;
    margin: 0 auto;
}

.commentModal .main li .msg .other {
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: flex-start;
}

.commentModal .main li .msg .other>img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px;
}

.commentModal .main li .msg .other .chatoneName {
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #73ABFF;
    margin-bottom: 10px;
}

.commentModal .main li .msg .other .toLeft {
    max-width: 80%;
    padding: 7px 12px;
    background: #2D323E;
    border-radius: 0px 16px 16px 16px;
}

.commentModal .main li .msg .other .toLeft span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.commentModal .main li .msg .other .toLeft div {
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #EAE6E6;
    margin-left: 4px;
}

.commentModal .main li .msg .me {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 12px;
}

.commentModal .main li .msg .me .toRight {
    max-width: 80%;
    padding: 7px 12px;
    background: #9C94FF;
    border-radius: 16px 16px 0px 16px;
}

.commentModal .main li .msg .me .toRight span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}


.commentModal .bott {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background: #000000;
    display: flex;
    padding: 0 16px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.commentModal .bott .inps {
    width: 70%;
    height: 44px;
    border-radius: 27px;
    background: #2D323E;
    margin-right: 12px;
    position: relative;
}

.commentModal .bott .inps svg {
    position: absolute;
    left: 16px;
    top: 25%;
}

.commentModal .bott .inps input {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 27px;
    border: 0;
    outline: 0;
    padding-left: 52px;
    padding-right: 16px;
    color: #FFFFFF;
}

.commentModal .bott>svg:nth-child(1) {
    margin-right: 15px;
}

.blockFriends ion-content {
    --background: #2D323E;
}

.blockFriend {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;

}

.blockFriend>img {
    width: 60px;
    height: 60px;
}

.blockFriend>p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin-top: 12px;
    margin-bottom: 24px;
}

.blockFriend>div {
    width: 327px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FF6881;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #000000;

}


/* user info modal  */

.avatarModal {
    --border-radius: 0;
    --border: none;
    --backdrop-opacity: 0;
    border: none;
    --ion-font-family: Open;
    z-index: 300002 !important;
}

ion-modal.avatarModal::part(content) {
    --background: #101A25 !important;
    overflow: visible !important;
}

.avatarModal ion-content {
    --background: #101A25 !important;
    --color: #FFFFFF;
    /* --padding-top: 32px; */
    --padding-start: 0;
    --padding-end: 0;
    --border: none !important;
    border: none;
}

.avatarModal ion-content::part(scroll) {
    --border: none !important;
    border: none;
    overflow: visible !important;
}

.avatarModal ion-content::part(scroll)::before {
    display: none;
}

.avatarModal .modalmain {
    width: 98%;
    margin: 0 auto;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #2D323E;
    border-radius: 8px;
    padding-top: 48px;
}

.avatarModal .modalmain img {
    position: absolute;
    left: calc(50% -32px);
    top: -32px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.avatarModal .modalmain .des {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.avatarModal .modalmain .des span {
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
}

.avatarModal .modalmain .des p {
    font-size: 12px;
    font-weight: 400;
    color: #CCCCCC;
    text-align: center;
    margin-top: 8px;
}

.avatarModal .modalmain ul {
    display: flex;
    align-items: center;
}

.avatarModal .modalmain ul li {
    width: 101px;
    height: 64px;
    background: #101A25;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 600;
    color: #9C94FF;
}

.avatarModal .modalmain ul li:last-child {
    margin-right: 0;
}

.avatarModal .modalmain ul li svg {
    margin-bottom: 6px;
}


/* addFriendModal */
.addFriendModal {
    color: #FFFFFF;
    position: relative;
}

.addFriendModal .tit {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #334663; */
    margin-bottom: 24px;
    background: #000;
}

.addFriendModal .tit span {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    color: #FFF !important;
    font-size: 16px !important;
    font-family: Open Sans;
    font-weight: 600 !important;
    line-height: 24px !important;
}

.addFriendModal .acts {
    width: 100%;
    height: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.addFriendModal .acts li {
    width: 100%;
    height: 36px;
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.addFriendModal .acts li svg {
    margin-right: 10px;
}

.addFriendModal .contain {
    width: 100%;
    padding: 0 16px;
}

.addFriendModal .inps {
    width: 100%;
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;
    margin: 0;
    margin-bottom: 32px;
    background: #101A25;
}

.addFriendModal .inps:focus-within {
    border-color: #9C94FF;
}

.addFriendModal .inps:focus-within span,
.addFriendModal .inps:focus-within div {
    color: #9C94FF;
}

.addFriendModal .inps input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    background: transparent;
}

.addFriendModal .inps span {
    position: absolute;
    top: -12px;
    left: 16px;
    background: #101A25;
    padding: 0 4px;
    color: #CCCCCC;
    font-size: 14px;
}

.addFriendModal .inps div {
    position: absolute;
    left: 16px;
    font-size: 16px;
    color: #CCCCCC;
}

.addFriendModal .create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
    margin: 0 auto;
    height: 48px;
    background: #9C94FF;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

/* newChatModal */
.newChatModal .tit {
    height: 48px;
    width: 100%;
    /* padding: 0 16px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.newChatModal .tit>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.newChatModal .tit>div p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
}

.newChatModal .tit>span {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #9C94FF;
    padding: 0 16px;
}

.newChatModal .addSerch {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

.newChatModal .addSerch>div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000;
    padding-left: 16px;
    border-radius: 8px;
}

.newChatModal .addSerch input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: #000000;
    border-radius: 8px;
    color: #FFFFFF;
}

.newChatModal .addSerch span {
    flex-shrink: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.newChatModal .checked {
    overflow-x: scroll;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.newChatModal .checked .checkedAct {
    margin-right: 20px;
}

.newChatModal .checked .checkedAct>div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
}

.newChatModal .checked .checkedAct>div svg {
    position: absolute;
    top: 0;
    right: -8px;
}

.newChatModal .checked .checkedAct img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex-shrink: 0;
}

.newChatModal .checked .checkedAct p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}

.newChatModal .checked .checkedAct:last-child {
    margin-right: 0;
}

.newChatModal .addlists {
    width: 100%;
    margin-top: 16px;
}

.newChatModal .addlists li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 4px 16px;
}

.newChatModal .addlists li>div {
    display: flex;
    align-items: center;
}

.newChatModal .addlists li>div img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-left: 12px;
    margin-right: 16px;
}

.newChatModal .addlists li>span {
    flex: 1;
    height: 48px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #334663;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newChatModal .addlists li>span div {
    padding: 4px 8px;
    border: 1px solid #9C94FF;
    border-radius: 8px;
    font-weight: 400;
    color: #9C94FF;
    font-size: 12px;
}

/* createChatModal */
.createChatModal .tit {
    height: 48px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.createChatModal .tit>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.createChatModal .tit>span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #9C94FF;
}

.createChatModal .imgAndName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    margin-bottom: 42px;
}

.createChatModal .imgAndName .imgdiv {
    width: 82px;
    height: 82px;
    background: #2D323E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.createChatModal .imgAndName .imgdiv>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.createChatModal .inps {
    width: 70%;
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;
}

.createChatModal .inps:focus-within {
    border-color: #9C94FF;
}

.createChatModal .inps:focus-within span,
.createChatModal .inps:focus-within div {
    color: #9C94FF;
}

.createChatModal .inps input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    background: transparent;
}

.createChatModal .inps span {
    position: absolute;
    top: -12px;
    left: 16px;
    background: #101A25;
    padding: 0 4px;
    color: #CCCCCC;
    font-size: 14px;
}

.createChatModal .inps div {
    position: absolute;
    left: 16px;
    font-size: 16px;
    color: #CCCCCC;
}

.createChatModal .members {
    width: 100%;
    padding: 0 16px;
}

.createChatModal .members>p {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.createChatModal .members .showList {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: scroll;
}

.createChatModal .members .showList .one {
    width: 60px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createChatModal .members .showList .one>div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    position: relative;
}

.createChatModal .members .showList .one>div>svg {
    position: absolute;
    top: 0;
    right: -8px;
}

.createChatModal .members .showList .one>div img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.createChatModal .members .showList .one>p {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
}

.createChatModal .members .showList .one:last-child {
    margin-right: 0;
}

.clickmodalleft {
    display: flex;
    align-items: center;
}

.clickmodalleft>img {
    height: 77px;
    width: auto;
}

.clickmodaldetail {
    flex: 1;
    padding-left: 16px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.clickmodaldetail>p {
    margin: 0 !important;
    font-family: 'Open Sans';
    font-style: normal;
    color: #FFFFFF;
}

.clickmodaldetail p:nth-child(1) {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
}

.clickmodaldetail p:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.clickmodaljoinbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
    margin-bottom: 18px;
    border: 1px solid #334663;
    border-radius: 8px;
    padding: 6px 0;
}

.clickmodaljoinbox .beting {
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    color: #FFFFFF;
}

.clickmodaljoinbox .beting .fontsize1 {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
}

.clickmodaljoinbox .beting .fontsize2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.clickmodaljoinbox>svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.clickmodaljoinbox .winning {
    font-family: 'Open Sans';
    font-style: normal;
    color: #42E05C;
}

.clickmodaljoinbox .winning .fontsize1 {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
}

.clickmodaljoinbox .winning .fontsize2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

/* modal channel */
.typeMessage {
    flex-shrink: 0;
    background: #000;
    width: 100%;
    height: 90px;
    padding: 16px 10px 30px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99999 !important;
    position: relative;
}

.typeMessage .picker {
    position: absolute;
    bottom: 90px;
    left: 5%;
}

.inps {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 44px;
    /* width: 74%; */
    width: calc(100% - 96px);
    border-radius: 27px;
    background: #2D323E;
    margin-left: 15px;
    margin-right: 12px;
}

.inps svg {
    cursor: pointer;
    margin-right: 8px;
}

.inps textarea {
    width: 100%;
    background: transparent;
    outline: none;
    outline-style: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    -webkit-appearance: none;
    appearance: none;
    -webkit-user-select: auto;
    user-select: auto;
    resize: none;
}

.inps input::-webkit-input-placeholder {
    color: #A6A9B8;
    font-weight: 400;
    font-size: 16px;
}

.typeMessage .sub {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9C94FF;
    cursor: pointer;
    padding-left: 6px;
}

/* invite list  */
.inviteList .tit {
    height: 48px;
    width: 100%;
    /* padding: 0 16px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.inviteList .tit>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.inviteList .tit>span {
    min-width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #9C94FF;
    padding: 0 16px;
}

.inviteList .lists {
    padding: 0 16px;
}

.inviteList .lists li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: normal;
    padding: 16px;
    border-radius: 12px;
    background: #1E232D;
    position: relative;
}

.inviteList .lists li>svg {
    flex-shrink: 0;
    position: absolute;
    top: 12px;
    right: 22px;
}

.inviteList .lists li .top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.inviteList .lists li .top>img {
    flex-grow: 0;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
}

.inviteList .lists li .top>div {
    display: flex;
    flex-direction: column;
}

.inviteList .lists li .top>div span {
    color: #FFF;
    font-family: 'Open';
    font-size: 16px;
    font-weight: 600;
    min-height: 22px;
}

.inviteList .lists li .top>div p {
    color: #898A8D;
    font-family: 'Open';
    font-size: 14px;
    font-weight: 400;
}

.inviteList .lists li .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.inviteList .lists li .bottom>div {
    display: flex;
    align-items: center;
}

.inviteList .lists li .bottom>div span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 12px;
    border-radius: 6px;
    font-family: 'Open';
    font-weight: 400;
    font-size: 12px;
}

.inviteList .lists li .bottom>i {
    font-family: 'Open';
    font-weight: 400;
    font-size: 12px;
    color: #979797;
    font-style: initial;
}

ion-checkbox {
    --size: 32px;
    --background-checked: #9C94FF;
}

ion-checkbox::part(container) {
    border-radius: 21px;
    width: 24px;
    height: 24px;
    --background: '#A10125';
    border: 1px solid #A6A9B8;
}

/* new Group Modal */
.newGroupInfo {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
}

.avatarInput {
    outline: none;
    border: 0;
    width: 82px;
    height: 82px;
    background: #2D323E;
    border-radius: 83px;
    margin-right: 10px;
    position: relative;
}

.avatarInput .avatarInputIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.newGroupName {
    width: calc(100% - 92px);
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 6px;
}

.newGroupInfo .newGroupName:focus-within {
    border-color: #9C94FF;
}

.newGroupInfo .newGroupName:focus-within span {
    color: #9C94FF;
}

.newGroupInfo .newGroupName input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    background: transparent;
    text-align: left;
    color: #fff;
}

.newGroupInfo .newGroupName span {
    position: absolute;
    top: -12px;
    left: 16px;
    background: #101A25;
    padding: 0 4px;
    color: #CCCCCC;
    font-size: 14px;
}

.newGroupMember {
    margin-top: 42px;
    padding: 0 12px;
}

.newGroupMemberTitle {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 14px;
}

.memberAvatar {
    display: flex;
    flex-wrap: wrap;
    /* padding: 0 16px 16px; */
}

.newGroupMember .addMember {
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border: 1px solid #334663;
    border-radius: 61px;
    margin-right: 16px;
    margin-bottom: 10px;
    position: relative;
}

.addMember .addMemberIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.addMy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 10px;
    /* width: 60px;
    height: 60px; */
    position: relative;
}