ion-modal.groupVoucherChoose {
  z-index: 300002 !important;
  padding-bottom: 0 !important;
}
.giftOption {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.giftOption .tit {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
  color: #ffffff;
  margin-bottom: 16px;
}
.giftOption .tit > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.giftOption .tit > div p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cccccc;
}
.giftOption .tit > span {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #9c94ff;
  padding: 0 16px;
}
.giftOption .main {
  flex: 1;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.giftOption .main .addlists {
  width: 100%;
  margin-top: 16px;
}
.giftOption .main .addlists li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 4px 16px;
}
.giftOption .main .addlists li > div {
  display: flex;
  align-items: center;
}
.giftOption .main .addlists li > div img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 16px;
}
.giftOption .main .addlists li > span {
  flex: 1;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border-bottom: 1px solid #334663;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.giftOption .main .addlists li > span div {
  padding: 4px 8px;
  border: 1px solid #9c94ff;
  border-radius: 8px;
  font-weight: 400;
  color: #9c94ff;
  font-size: 12px;
}
