.chat_li {
  box-sizing: border-box;
  :global {
    .main_content {
      align-items: flex-end;
      display: flex;
      margin-bottom: 30px;
      padding: 0 16px;
      .theMessage {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        .msg_box {
          &.self {
            flex-direction: row-reverse;
            .chatroom__message--emoji-picker {
              inset-inline-start: auto;
              inset-inline-end: 0;
            }
            .reply_btn {
              inset-inline-start: auto;
              inset-inline-end: 0;
            }
            .chatroom__message--emoji-comment {
              right: 0;
              left: auto;
            }
          }
          &.other {
            .chatroom__message--emoji-comment {
              right: auto;
              left: 0;
            }
            .chatroom__message--emoji-picker {
              inset-inline-start: 0;
              inset-inline-end: auto;
            }
            .reply_btn {
              inset-inline-end: auto;
              inset-inline-start: 0;
            }
          }
          align-items: flex-start;
          display: flex;
          width: 100%;
          .msgMain {
            .message_chat_content {
              display: flex;
              gap: 6px;
              & > img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
              }
              .message_chat_info {
                display: flex;
                flex-direction: column;
                gap: 4px;
                & > span {
                  line-height: 1;
                  font-size: 14px;
                }
              }
            }
            display: flex;
            flex-direction: column;
            max-width: 70%;
            width: fit-content;
            position: relative;
            .msg_quoted {
              align-items: flex-start;
              display: flex;
              margin-bottom: 4px;
              width: 100%;
              gap: 8px;
              > img {
                border-radius: 50%;
                flex-shrink: 0;
                width: 32px;
                height: 32px;
                object-fit: cover;
              }
              .msg_quoted_main {
                span{
                  font-size: 12px;
                }
                div {
                  border-radius: 3px 16px 16px 16px;
                  padding: 8px 16px;
                  word-break: break-all;
                  p{
                    font-size: 12px;
                  }
                }
              }
            }
            > div {
              font-size: 17px;
              line-height: 23px;
              box-sizing: border-box;
            }
          }
          .msg_acts {
            align-items: center;
            display: flex;
            margin-left: 8px;
            padding-top: 8px;
            .actions_box {
              display: flex;
              align-items: center;
              position: relative;
              .msg_action_box {
                .reply_btn {
                  z-index: 340000;
                  inset-block-start: -32px;
                  position: absolute;
                  min-width: 10rem;
                  background: #17191c;
                  margin-left: 8px;
                  color: #fff;
                  padding: 8px 16px;
                  border-radius: 14px;
                  box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
                  font-size: 16px;
                }
              }
              .action_btn {
                background: transparent;
                margin-left: 8px;
                line-height: 1;
                appearance: none;
                &.msg_action_btn svg {
                  width: 16px;
                  height: 16px;
                }
                &.emoji_action_btn svg {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
      .nameAndTime {
        color: #72767e;
        display: flex;
        font-size: 12px;
        align-items: center;
        .times {
          align-items: center;
          display: flex;
          margin-left: 4px;
          line-height: 2;
          .status {
            align-items: center;
            color: #337eff;
            display: flex;
            margin-right: 4px;
            svg {
              width: 15px;
            }
          }
        }
      }
    }
  }
}
.chatRoom {
  :global {
    .main_content {
      align-items: center;
      .theMessage {
        align-items: center;
        .msg_box {
          .msgMain {
            margin: 0 auto;
            max-width: none;
            max-width: 80%;
          }
          .msg_acts {
            bottom: 19%;
            position: absolute;
            right: 6px;
          }
        }
      }
    }
  }
}
