:root {
  --main-background-color: #101a25;
  --modal-padding: 16px;
  --border-color: #334663;
  --primary: #9c94ff;
}
ion-modal.sign-up,
ion-modal.sign-in {
  padding-bottom: 0 !important;
  z-index: 30000 !important;
  background-color: var(--main-background-color);
}
ion-modal.forgot-password {
  padding-bottom: 0 !important;
  z-index: 30001 !important;
  background-color: var(--main-background-color);
}

.forgot-password {
  ion-content {
    --padding-start: var(--modal-padding);
    --padding-end: var(--modal-padding);
    .forgot-password-modal {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #334663;
        svg {
          cursor: pointer;
        }
        span {
          font-size: 16px;
        }
      }
      &__content {
        &--icon {
          margin-block: 30px;
          display: flex;
          justify-content: center;
        }
        & > p {
          padding: 0 20px;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}

.sign-up,
.sign-in {
  ion-content {
    // --padding-top: var(--modal-padding);
    --padding-start: var(--modal-padding);
    --padding-end: var(--modal-padding);
    .sign-up-modal,
    .sign-in-modal {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #334663;
        svg {
          cursor: pointer;
        }
        span {
          font-size: 16px;
        }
      }
      &__content {
        &--or {
          position: relative;
          border-top: 1px solid #334663;
          text-align: center;
          span {
            font-size: 12px;
            color: #cccccc;
            padding: 0 2px;
            background-color: var(--main-background-color);
            transform: translateY(-50%);
            position: absolute;
          }
        }
        &--social {
          margin-top: 20px;
          li {
            margin-bottom: 16px;
            button {
              justify-content: flex-start;
              padding: 0 20px;
              svg {
                margin-right: 10px;
              }
            }
            &:last-child {
              margin-bottom: 10px;
            }
          }
        }
        &--link {
          font-size: 12px;
          color: #cccccc;
          span {
            color: #9c94ff;
            padding-left: 5px;
          }
        }
        &--forget {
          color: #9c94ff;
          font-size: 12px;
          letter-spacing: 3%;
          padding-left: 10px;
          margin-bottom: 20px;
        }
      }
      &__content--link {
        margin-top: 25px;
        > span {
          cursor: pointer;
        }
      }
    }
  }
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.hight-level {
  z-index: 30001 !important;
}
.sign-up-modal__tips {
  font-size: 12px;
  color: #cccccc;
  margin-bottom: 20px;
  span {
    color: #9c94ff;
  }
}

.auth-form {
  margin: 16px 0;
  &-group {
    position: relative;
    margin-bottom: 16px;
    &-control {
      transition: border-color 0.3s;
      position: relative;
      border: 1px solid #334663;
      border-radius: 8px;
      position: relative;
      width: 100%;
      height: 56px;
      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      label {
        position: absolute;
        font-size: 12px;
        color: #cccccc;
        left: 20px;
        top: -10px;
        padding: 0 5px;
        background-color: var(--main-background-color);
      }
      input {
        background-color: var(--main-background-color);
        padding: 0 16px;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        background-color: transparent;
        &:-internal-autofill-selected {
          background-color: transparent;
        }
      }
      .form-password {
        width: 90%;
      }
      &:has(input:focus) {
        transition: border-color 0.3s;
        border-color: #9c94ff;
      }
    }
    p {
      font-size: 12px;
      padding-left: 12px;
      height: 17px;
      color: #ff6881;
    }
    .password-rules {
      margin-top: 10px;
      z-index: 30002;
      position: absolute;
      width: 100%;
      border-radius: 12px;
      background-color: #2d323e;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s;
      li {
        display: flex;
        gap: 6px;
        align-items: center;
        margin-bottom: 16px;
        span {
          font-size: 14px;
        }
      }
      &.show {
        padding: 16px;
        transition: all 0.3s;
        max-height: 400px;
      }
    }
  }
  button[type='submit'] {
    background-color: #9c94ff;
    color: #101a25;
    border: none;
  }
}
.chatroom__message {
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  gap: 12px;
  padding: 16px 12px;
  width: 80vw;
  max-width: 600px;
  background-color: #9c94ff;
  display: flex;
  align-items: center;
  &--emoji {
    width: 100%;
    z-index: 30005;
    position: absolute;
    top: -70px;
    left: 0;
    &:hover {
      display: block;
    }
    &-picker {
      left: -30px;
      position: absolute;
    }
    &-comment {
      position: absolute;
      bottom: -68px;
      left: 0;
    }
  }

  &--avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &--info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    p {
      white-space: pre-wrap;
      font-size: 16px;
      font-weight: 600;
      color: #101a25;
    }
  }
  &--emoji-btn {
    position: absolute;
    bottom: 0;
    right: -22px;
    svg {
      height: 18px;
      width: 18px;
      fill: #72767e;
    }
  }
  .chatroom__message--time {
    font-size: 12px;
    color: #cccccc;
  }
}
.CustomChat .chatroom__message--emoji-picker {
  right: 0;
}
.chatroom__header {
  padding: 20px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #101a25;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 24px);
    background: #334663;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &--dropdown {
    display: flex;
    gap: 10px;
    align-items: center;
    span {
      font-size: 16px;
      color: #fff;
    }
  }
  &--people {
    display: flex;
    gap: 4px;
    align-items: center;
    span {
      font-size: 12px;
      color: #fff;
    }
  }
}
.chatroom {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 12px 110px;
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    &--list {
      max-height: calc(100vh - 62px - 110px - 30px);
      overflow: scroll;
      & > ul {
        padding: 70px 0;
        & > li {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
        }
      }
      &--send {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        &--input {
          flex: 1;
          display: flex;
          align-items: center;
          background-color: #2d323e;
          height: 44px;
          padding: 12px;
          border-radius: 25px;
          gap: 8px;
          svg {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
          }
          input {
            border: none;
            outline: none;
            font-size: 16px;
            flex: 1;
            background-color: transparent;
          }
        }
        & > span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background-color: #9c94ff;
        }
      }
    }
  }
}
.channels {
  padding: 0 1rem;
  ion-item {
    --ion-item-background: #101a25;
    --inner-border-width: 0;
  }
  ion-item-sliding {
    margin-bottom: 8px;
  }
  .channel-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
    span {
      text-transform: capitalize;
      color: #000;
      font-size: 12px;
    }
  }
  .channel {
    display: flex;
    width: 100%;
    // padding: 0 16px;
    gap: 16px;
    &__avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      flex-shrink: 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
      }
    }
    &__main {
      flex: 1;
      border-bottom: 1px solid #334663;
      padding-bottom: 4px;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      &--name {
        font-size: 14px;
        color: #fff;
      }
      &--time {
        color: #9c94ff;
        font-size: 12px;
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 12px;
        color: #cccccc;
      }
      span {
        flex-shrink: 0;
        align-self: flex-end;
        font-size: 12px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #9c94ff;
        color: #171821;
      }
    }
  }
}

.block-modal {
  --backdrop-opacity: 0;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  --background: #2d323e;
  z-index: 30001 !important;
  --ion-backdrop-color: #fff;
  backdrop-filter: brightness(0.5);
  ion-content {
    background-color: #2d323e;
    display: flex;
    align-items: center;
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
    .block-modal__content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: center;
      &--avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
        }
      }
      p {
        font-size: 14px;
        color: #fff;
      }
      button {
        border: none;
        background-color: #ff6881;
        color: #000;
      }
    }
  }
}

.personal-chat-modal,
.group-chat-modal {
  z-index: 300001 !important;
  padding-bottom: 0 !important;
}
.personal-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    height: 56px;
    padding: 12px;
    background-color: #000;
    display: flex;
    align-items: center;
    gap: 12px;
    &--info {
      gap: 12px;
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: contain;
        object-position: center center;
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        p {
          line-height: 1;
        }
        &-member {
          line-height: 1;
          span {
            padding-left: 4px;
            font-size: 12px;
            line-height: 1;
          }
        }
      }
    }
    &--search {
      background-color: #101a25;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      flex: 1;
      gap: 8px;
      svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
      input {
        flex: 1;
        border: none;
        outline: none;
        background-color: #101a25;
      }
    }
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    height: calc(100% - 56px);
    &--list {
      flex: 1;
      overflow: scroll;
      padding-block-start: 24px;
      & > ul {
        & > li {
          margin-bottom: 20px;
          &.self {
            display: flex;
            justify-content: flex-end;
            p {
              font-size: 14px;
              max-width: 290px;
              padding: 12px;
              background-color: #9c94ff;
              border-radius: 16px 16px 2px 16px;
              color: #000000;
            }
          }
          &.other {
            display: flex;
            gap: 12px;
            .other__avatar {
              flex-shrink: 0;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .other__info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              span {
                font-size: 15px;
              }
              p {
                max-width: 285px;
                background-color: #19232e;
                border-radius: 2px 16px 16px 16px;
                font-size: 14px;
                color: #e0e1eb;
                padding: 12px;
              }
            }
          }
        }
      }
    }
    &--send {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 12px 0;
      &--input {
        flex: 1;
        display: flex;
        align-items: center;
        background-color: #2d323e;
        height: 44px;
        padding: 12px;
        border-radius: 25px;
        gap: 8px;
        svg {
          flex-shrink: 0;
          width: 20px;
          height: 20px;
        }
        input {
          border: none;
          outline: none;
          font-size: 16px;
          flex: 1;
          background-color: transparent;
        }
      }
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #9c94ff;
      }
    }
  }
}
.personal-chat-modal,
.group-chat-modal {
  // z-index: 30001 !important;
  padding-bottom: 0 !important;
}
.chat-controller {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    padding: 12px;
    background-color: #000;
    display: flex;
    align-items: center;
    gap: 12px;
    &--info {
      gap: 12px;
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: contain;
        object-position: center center;
      }
    }
    &--search {
      background-color: #101a25;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      flex: 1;
      gap: 8px;
      svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
      input {
        flex: 1;
        border: none;
        outline: none;
        background-color: #101a25;
      }
    }
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    &--list {
      margin-top: 10px;
      height: calc(100vh - 72px - 68px);
      overflow: scroll;
      & > ul {
        & > li {
          margin-bottom: 20px;
          &.self {
            display: flex;
            justify-content: flex-end;
            p {
              font-size: 14px;
              max-width: 290px;
              padding: 12px;
              background-color: #9c94ff;
              border-radius: 16px 16px 2px 16px;
              color: #000000;
            }
          }
          &.other {
            display: flex;
            gap: 12px;
            .other__avatar {
              flex-shrink: 0;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .other__info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              span {
                font-size: 15px;
              }
              p {
                max-width: 285px;
                background-color: #19232e;
                border-radius: 2px 16px 16px 16px;
                font-size: 14px;
                color: #e0e1eb;
                padding: 12px;
              }
            }
          }
        }
      }
    }
    &--send {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 20px 0;
      position: relative;
      &--emoji-picker {
        z-index: 30301;
        bottom: 80px;
        position: absolute;
      }
      &--input {
        flex: 1;
        display: flex;
        align-items: center;
        background-color: #2d323e;
        height: 44px;
        padding: 12px;
        border-radius: 25px;
        gap: 8px;
        svg {
          flex-shrink: 0;
          width: 20px;
          height: 20px;
        }
        input {
          border: none;
          outline: none;
          font-size: 16px;
          flex: 1;
          background-color: transparent;
        }
      }
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #9c94ff;
      }
    }
  }
}
.other_chat_txt_box {
  background: rgb(45, 50, 62);
  border-radius: 2px 16px 16px 16px;
  padding: 8px 16px;
}
.self_chat_txt_box {
  background: #9c94ff;
  border-radius: 16px 2px 16px 16px;
  padding: 8px 16px;
  color: rgb(0, 0, 0);
}
.public_chat_txt_box {
  background: #9c94ff;
  border-radius: 18px;
  padding: 8px 12px;
  display: flex;
  min-height: 92px;
  max-width: 284px;
  align-items: center;
  > img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  > p {
    color: #101a25;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    padding: 0 0 0 12px;
  }
}
.group-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    padding: 12px;
    background-color: #000;
    display: flex;
    align-items: center;
    gap: 12px;
    &--info {
      gap: 12px;
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: contain;
        object-position: center center;
      }
    }
    &--search {
      background-color: #101a25;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      flex: 1;
      gap: 8px;
      svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
      input {
        flex: 1;
        border: none;
        outline: none;
        background-color: #101a25;
      }
    }
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    &--list {
      margin: 10px 0;
      height: calc(100vh - 62px - 40px - 44px);
      overflow: scroll;
      > ul {
        > li {
          margin-bottom: 20px;
          &.self {
            display: flex;
            justify-content: flex-end;
            p {
              font-size: 14px;
              max-width: 290px;
              padding: 12px;
              background-color: #9c94ff;
              border-radius: 16px 16px 2px 16px;
              color: #000000;
            }
          }
          &.other {
            display: flex;
            gap: 12px;
            .other__avatar {
              flex-shrink: 0;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .other__info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              span {
                font-size: 15px;
              }
              p {
                max-width: 285px;
                background-color: #19232e;
                border-radius: 2px 16px 16px 16px;
                font-size: 14px;
                color: #e0e1eb;
                padding: 12px;
              }
            }
          }
        }
      }
    }
    &--send {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 12px 0;
      &--input {
        flex: 1;
        display: flex;
        align-items: center;
        background-color: #2d323e;
        height: 44px;
        padding: 12px;
        border-radius: 25px;
        gap: 8px;
        svg {
          flex-shrink: 0;
          width: 20px;
          height: 20px;
        }
        input {
          border: none;
          outline: none;
          font-size: 16px;
          flex: 1;
          background-color: transparent;
        }
      }
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #9c94ff;
      }
    }
  }
}
.personal-chat {
  [class*='chatItem_chat_li']:first-child {
    .msg_box {
      .chatroom__message--emoji-picker {
        bottom: -162px;
      }
    }
  }
}

.list-ios {
  background: transparent;
}

.ion-page {
  height: 100%;
  overflow-y: hidden;
}
.ion-delegate-host.ion-page {
  overflow-y: unset !important;
}

.list-md{
  background: transparent !important;
}


.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #fff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}